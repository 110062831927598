.search-user-location{
  font-size: 14px;
  text-decoration: underline var(--bt-color-primary);
  color: var(--bt-color-primary);
  display: inline-flex;
  float: right;
  margin-bottom: 15px;
  margin-top: -16px;
  cursor: pointer;
}
