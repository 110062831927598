.city-filter {
  width: 100%;
}

.tab-content-item .city-filter {
  padding: 0;
}

.city-filter > .collapse-content {
  border-bottom: 0 none;
}

.city-filter .header {
  margin-bottom: 16px;
  padding: 0;
}

.city-filter .collapsible {
  padding: 0;
}